import { IForm, FormMappingFilters } from '../../models/form.interface';

export class UpdateQueryParams {
  static readonly type = '[FormMapping] Update Query Params';
  constructor(public payload: Partial<FormMappingFilters>) {}
}

export class LoadData {
  static readonly type = '[FormMapping] Load Data';
}

export class LoadMoreData {
  static readonly type = '[FormMapping] Load More Data';
}

export class UpdateFormMapping {
  static readonly type = '[FormMapping] Update Form';
  constructor(
    public id: string,
    public payload: IForm,
  ) {}
}

export class FetchFormById {
  static readonly type = '[FormMapping] Fetch Form By ID';
  constructor(public id: string) {}
}

export class ResetForm {
  static readonly type = '[FormMapping] Reset Form';
  constructor(public id: string) {}
}
