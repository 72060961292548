import { inject, Injectable } from '@angular/core';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { FormMappingFilters, IForm, IFormMapping } from '../../models/form.interface';
import {
  FetchFormById,
  LoadData,
  LoadMoreData,
  ResetForm,
  UpdateFormMapping,
  UpdateQueryParams,
} from './form-mapping.actions';
import { catchError, finalize, first, of, tap } from 'rxjs';
import { StartLoading, StopLoading } from '@core/store';
import { ShowAlert } from '@shared/store';
import { AlertType } from '@shared/enums';
import { FormService } from '@shared/services/form/form.service';
import { ITenant } from '@shared/models/tenant.model';
import { ICountry } from '@shared/models';

export interface FormMappingStateModel {
  data: IFormMapping[];
  form: IForm | null;
  queryParams: FormMappingFilters;
  loading: boolean;
  loadingMore: boolean;
  pageNumber: number;
  hasMoreData: boolean;
  error: string | null;
}

@State<FormMappingStateModel>({
  name: 'formMapping',
  defaults: {
    data: [],
    form: null,
    queryParams: {},
    loading: false,
    loadingMore: false,
    pageNumber: 1,
    hasMoreData: false,
    error: null,
  },
})
@Injectable()
export class FormMappingState {
  private formService = inject(FormService);
  private store = inject(Store);

  @Selector()
  static getState(state: FormMappingStateModel) {
    return state;
  }

  @Selector()
  static data(state: FormMappingStateModel) {
    return state.data;
  }

  @Selector()
  static form(state: FormMappingStateModel) {
    return state.form;
  }

  @Selector()
  static loading(state: FormMappingStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static loadingMore(state: FormMappingStateModel) {
    return state.loadingMore;
  }

  @Selector()
  static error(state: FormMappingStateModel) {
    return state.error;
  }

  @Action(UpdateQueryParams)
  updateQueryParams(ctx: StateContext<FormMappingStateModel>, action: UpdateQueryParams) {
    const state = ctx.getState();
    ctx.patchState({
      queryParams: {
        ...state.queryParams,
        ...action.payload,
      },
      pageNumber: 1,
      data: [],
    });
    ctx.dispatch(new LoadData());
  }

  @Action(LoadData)
  loadData(ctx: StateContext<FormMappingStateModel>) {
    this.store.dispatch(new StartLoading());
    const { pageNumber, queryParams } = ctx.getState();
    ctx.patchState({ loading: true, error: null });

    return this.formService.getForms(pageNumber, queryParams).pipe(
      first(),
      tap((res) => {
        ctx.patchState({
          data: res.data,
          loading: false,
          hasMoreData: res.data.length > 0,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false,
          error: error.error ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
      finalize(() => {
        this.store.dispatch(new StopLoading());
      }),
    );
  }

  @Action(LoadMoreData)
  loadMoreData(ctx: StateContext<FormMappingStateModel>) {
    const { pageNumber, queryParams, hasMoreData } = ctx.getState();
    if (!hasMoreData) {
      return;
    }

    ctx.patchState({ loadingMore: true });

    return this.formService.getForms(pageNumber + 1, queryParams).pipe(
      first(),
      tap((res) => {
        ctx.patchState({
          data: [...ctx.getState().data, ...res.data],
          loadingMore: false,
          hasMoreData: res.data.length > 0,
          pageNumber: pageNumber + 1,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loadingMore: false,
          error: error.error ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
    );
  }

  @Action(UpdateFormMapping)
  UpdateForm(ctx: StateContext<FormMappingStateModel>, action: UpdateFormMapping) {
    this.store.dispatch(new StartLoading());
    return this.formService.updateForm(action.id, action.payload).pipe(
      first(),
      tap(() => {
        ctx.dispatch(new LoadData());
        this.store.dispatch(new ShowAlert({ message: 'Updated successfully', type: AlertType.Success }));
      }),
      catchError((error) => {
        ctx.patchState({
          error: error.error.errors ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
      finalize(() => {
        this.store.dispatch(new StopLoading());
      }),
    );
  }

  @Action(FetchFormById)
  fetchFormById(ctx: StateContext<FormMappingStateModel>, action: FetchFormById) {
    ctx.patchState({ form: null, error: null });
    return this.formService.getFormById(action.id).pipe(
      first(),
      tap((res: any) => {
        ctx.patchState({
          form: {
            title: res.title,
            description: res.description,
            associatedCountryOfCitizenshipIds: res.countryOfCitizenship.map((country: ICountry) => country.id),
            associatedCountryOfTravelIds: res.countryOfTravel.map((country: ICountry) => country.id),
            sections: res.sections,
            price: res.price,
            tenantIds: res.tenants.map((tenant: ITenant) => tenant.id),
          },
        });
      }),
      catchError((error) => {
        ctx.patchState({
          error: error.error ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
    );
  }

  @Action(ResetForm)
  resetFormById(ctx: StateContext<FormMappingStateModel>, action: ResetForm) {
    return this.formService.resetForm(action.id).pipe(
      first(),
      tap(() => {
        ctx.dispatch(new LoadData());
        this.store.dispatch(new ShowAlert({ message: 'Reset successfully', type: AlertType.Success }));
      }),
      catchError((error) => {
        ctx.patchState({
          error: error.error ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
    );
  }
}
